.footer1-footer1 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(241, 238, 246);
}
.footer1-max-width {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-content {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius4);
}
.footer1-newsletter {
  gap: 24px;
  width: 500px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-navlink {
  display: contents;
}
.footer1-image1 {
  height: 2rem;
  text-decoration: none;
}
.footer1-actions {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-form {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-shrink: 0;
}
.footer1-container {
  width: 365px;
  display: flex;
  align-items: flex-start;
}
.footer1-text-input {
  gap: 8px;
  width: 100%;
  height: 32px;
  display: flex;
  font-size: 16px;
  box-sizing: content-box;
  text-align: left;
  align-items: center;
  font-family: Roboto;
  font-weight: 400;
  background-color: transparent;
}
.footer1-content2 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  height: auto;
  font-size: 12px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.footer1-links {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-end;
}
.footer1-column1 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-footer-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-link1 {
  text-decoration: none;
}
.footer1-link2 {
  text-decoration: none;
}
.footer1-link3 {
  text-decoration: none;
}
.footer1-link4 {
  text-decoration: none;
}
.footer1-column2 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-footer-links1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-link6 {
  text-decoration: none;
}
.footer1-link7 {
  text-decoration: none;
}
.footer1-link5 {
  text-decoration: none;
}
.footer1-column3 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-social-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-link {
  display: contents;
}
.footer1-link1 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}
.footer1-link2 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer1-link3 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer1-icon2 {
  text-decoration: none;
}
.footer1-link4 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer1-link5 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}
.footer1-link6 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}
.footer1-link7 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}
.footer1-credits {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.footer1-row {
  gap: 64px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.footer1-footer-links2 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
.footer1-link11 {
  text-decoration: none;
}
.footer1-link12 {
  text-decoration: none;
}
.footer1-link13 {
  text-decoration: none;
}





@media(max-width: 991px) {
  .footer1-newsletter {
    width: 300px;
  }
  .footer1-form {
    width: 100%;
    flex-direction: column;
  }
  .footer1-container {
    width: 100%;
  }
  .footer1-text-input {
    width: 100%;
    padding: var(--dl-space-space-halfunit);
  }
  .footer1-button {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 767px) {
  .footer1-content {
    flex-direction: column;
  }
  .footer1-newsletter {
    width: 100%;
  }
  .footer1-form {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .footer1-container {
    width: 100%;
  }
  .footer1-button {
    width: 208px;
  }
  .footer1-links {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .footer1-column1 {
    align-items: center;
  }
  .footer1-column1-title {
    text-align: center;
  }
  .footer1-footer-links {
    align-self: center;
  }
  .footer1-column2 {
    align-items: center;
  }
  .footer1-column2-title {
    text-align: center;
  }
  .footer1-footer-links1 {
    align-self: center;
  }
  .footer1-column3 {
    align-items: center;
  }
  .footer1-social-link1-title {
    text-align: center;
  }
  .footer1-social-links {
    align-self: center;
  }
  .footer1-row {
    flex-direction: column;
  }
}
@media(max-width: 500px) {
  .footer1-actions {
    width: 100%;
  }
  .footer1-form {
    width: 100%;
    flex-direction: column;
  }
  .footer1-container {
    width: 100%;
  }
  .footer1-button {
    width: 100%;
  }
  .footer1-links {
    flex-direction: column;
  }
  .footer1-column1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer1-footer-links {
    align-items: center;
    justify-content: center;
  }
  .footer1-column2 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer1-footer-links1 {
    align-items: center;
    justify-content: center;
  }
  .footer1-column3 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer1-social-links {
    align-items: center;
    justify-content: center;
  }
  .footer1-credits {
    gap: 0;
  }
  .footer1-row {
    align-items: center;
    justify-content: center;
  }
  .footer1-footer-links2 {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
