.message{
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-color: rgb(4, 71, 16);
}
.message-width {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    background-color: rgb(225, 225, 225);
    border-radius: 10px;
}
.message-container {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-self: center;
    align-items: center;
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
}
.message-container01 {
    gap: var(--dl-space-space-twounits);
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
}
.message-container02 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.message-container03 {
    gap: var(--dl-space-space-unit);
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    justify-content: start;
}
.message-image {
    max-width: 240px;
    object-fit: cover;
    border-color: chocolate;
    border-width: 2px;
    border-radius: 50%;
}
.message-heading{
    font-family: Arial, Helvetica, sans-serif;
}
.message-text {
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}
.message-text1 {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

@media(max-width: 720px){
    .message-container {
        margin-bottom: var(--dl-space-space-twounits);
      }
    .message-container01{
        flex-direction: column;
        align-self: center;
    }
}

@media(max-width: 560px){
    .message-container {
        margin-bottom: var(--dl-space-space-unit);
    }
    .message-container01{
        flex-direction: column;
        align-self: center;
    }
}

@media(max-width: 360px) {
    .message-container {
        margin-bottom: var(--dl-space-space-unit);
      }
      .message-container01{
          flex-direction: column;
          align-self: center;
      }
}