/*
.head1-container {
  width: 100%;
  height: 424px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.head1-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.head1-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  background-size: cover;
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1509114397022-ed747cca3f65?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxzdGFyeSUyMHNreXxlbnwwfHx8fDE3MjAyNjg4MTZ8MA&ixlib=rb-4.0.3&w=1400");
  background-position: center;
}
.head1-text {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-light);
  align-self: center;
  text-align: center;
  text-transform: uppercase;
}
*/

.head1-container {
  width: 100%;
  height: 360px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}

.head1-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.head1-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.head1-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.head1-text {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-light);
  align-self: center;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
}

@media (max-width: 767px) {
  .head1-container {
    height: 300px;
  }

  .head1-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .head1-text {
    font-size: 24px;
  }
}