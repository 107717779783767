.hero-header5 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}
.hero-container01{
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 100%;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  align-self: center;
  text-align: center;
  color: #ffffff;
}
.hero-swiper {
  width: 100%;
  height: auto;
}
.hero-slide {
  position: relative;
  width: 100%;
  height: 100%;
}
.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-text-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
  color: #ffffff;  
}
.hero-text {
  text-align: left;
  font-family: "STIX Two Text";
  font-size: 36px;
}
.hero-text1 {
  text-align: left;
  font-size: 18px;
}
.swiper-pagination {
  position: absolute;
  bottom: 10px; 
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}
.swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-bullet-active {
  background: #ffffff;
}

@media(max-width: 767px) {
  .hero-header5 {
    height: auto;
    justify-content: center;
  }

  .hero-swiper{
    height: auto;
  }

  .hero-video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-text-container {
    bottom: 10px;
    left: 10px;
  }
}

@media(max-width: 380px) {
  .hero-header5{
    height: auto;
    justify-content: center;
  }

  .hero-text {
    font-size: 28px;
  }

  .hero-swiper{
    height: auto;
  }

  .hero-video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-text1 {
    font-size: 14px;
  }
}
