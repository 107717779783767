.bonus{
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
}
.bonus-width{
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.bonus-container01{
    gap:var(--dl-space-space-threeunits);
    text-align: center;
    color: white;
}
.bonus-container02{
    gap:var(--dl-space-space-threeunits);
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.bonus-heading{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    justify-content: center;
}
.bonus-text01{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    justify-content: center;
    color: #eda733;
}
.bonus-img{
    width: 50%;
    height: auto;
    object-fit: cover;
}
.intro-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ff8400;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    margin: 10px;
}
.intro-button:hover {
    background-color: #ffffff;
    color: #ff8400;
    margin: 10px;
}

@media (max-width: 720px) {
    .bonus-container02 {
        flex-direction: column;
    }
    .bonus-img {
        width: 70%;
    }
}

@media (max-width: 580px) {
    .bonus-container02 {
        flex-direction: column;
    }
    .bonus-img {
        width: 80%;
    }
}

@media (max-width: 320px) {
    .bonus-container02 {
        flex-direction: column;
    }
    .bonus-img {
        width: 100%;
    }
}