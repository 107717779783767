.discover{
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
}
.discover-width{
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.discover-container01{
    gap:var(--dl-space-space-threeunits);
    text-align: center;
    margin-bottom: 10px;
    color: white;
}
.discover-container02{
    gap:var(--dl-space-space-threeunits);
    text-align: center;
    margin-bottom: 10px;
}
.discover-heading{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    justify-content: center;
    margin-bottom: 30px;
}
.discover-text{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    justify-content: center;
}
.discover-img{
    width: 100%;
    height: auto;
    object-fit: cover;
}



@media (max-width: 720px) {
    .discover-container02 {
        flex-direction: column;
    }
    .discover-img {
        width: 70%;
    }
}

@media (max-width: 580px) {
    .discover-container02 {
        flex-direction: column;
    }
    .discover-img {
        width: 80%;
    }
}

@media (max-width: 320px) {
    .discover-container02 {
        flex-direction: column;
    }
    .discover-img {
        width: 100%;
    }
}