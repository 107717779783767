.intro {
    width: 100%;
    height: auto;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
}
.intro-width {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.intro-container00 {
    gap:var(--dl-space-space-threeunits);
    text-align: center;
    margin-bottom: 20px;
}
.intro-text {
    font-family: Arial, sans-serif;
    text-align: center;
    color: #ffb300;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    margin-left: 12.5%;
    margin-right: 12.5%;
}
.intro-text01 {
    font-family: Arial, sans-serif;
    text-align: center;
    color: #faf4f4;
    margin-right: 25%;
    margin-left: 25%;
}
.intro-container02 {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}
.intro-img1, .intro-img2 {
    width: 200px;
    height: auto;
}
.intro-img3 {
    width: 100%;
    height: auto;
}
.intro-timer {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    text-align: center;
}
.timer-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ffd000;
    color: #123300;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    transition: transform 0.2s;
    flex-direction: column;
}
.timer-interval {
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 5px;
}
.intro-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ff8400;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}
.intro-button:hover {
    background-color: #ffffff;
    color: #ff8400;
}
.intro-padding {
    padding-bottom: var(--dl-space-space-unit);
}

@media (max-width: 720px) {
    .intro-text01 {
        margin-left: 15%;
        margin-right: 15%;
    }
    .intro-container02 {
        flex-direction: column;
    }
    .intro-img1, .intro-img2 {
        width: 150px;
    }
}

@media (max-width: 580px) {
    .intro-text01 {
        margin-left: 20%;
        margin-right: 20%;
    }
    .intro-container02 {
        flex-direction: column;
    }
    .intro-img1, .intro-img2 {
        width: 120px;
    }
}

@media (max-width: 320px) {
    .intro-text01 {
        margin-left: 10%;
        margin-right: 10%;
    }
    .intro-container02 {
        flex-direction: column;
    }
    .intro-img1, .intro-img2 {
        width: 100px;
    }
}