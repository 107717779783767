.head3-container {
  width: 100%;
  height: 360px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}

.head3-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.head3-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.head3-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.head3-text {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-light);
  align-self: center;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
}

@media (max-width: 767px) {
  .head3-container {
    height: 300px;
  }

  .head3-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .head3-text {
    font-size: 24px;
  }
}
