.npl1-layout349 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: antiquewhite;
}
.npl1-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
}
.npl1-image-container {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
}
.npl1-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text {
  color: #134b42;
}
.img {
  width: 100%;
  object-fit: fill;
  aspect-ratio: auto;
  border-radius: var(--dl-radius-radius-imageradius);
}
.npl1-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.npl1-content1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.npl1-text3 {
  align-self: flex-start;
}
.npl1-text5 {
  align-self: flex-start;
}
.npl1-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
}
.npl1-button {
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.npl1-button-ready {
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: aqua;
  color: chocolate;
}

@media(max-width: 991px) {
  .npl1-max-width {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .npl1-actions {
    width: 100%;
    flex-direction: column;
  }
  .npl1-button {
    width: 100%;
  }
}
