.lpart3{
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-image: url('./main2.png'); /* Add this line */
    background-size: cover; /* Ensures the image covers the entire background */
    background-position: center; /* Centers the image */
}
.lpart3-width{
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.lpart3-container{
    gap:var(--dl-space-space-threeunits);
    text-align: center;
    margin-bottom: 10px;
}
.lpart3-container0{
    gap:var(--dl-space-space-threeunits);
    width: auto;
    display: flex;
    flex-direction: row;
}
.lpart3-container1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}
.lpart3-h1{
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
}
.lpart3-h2{
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    color: #ffffff;
}
.lpart3-text{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24;
    color: #ffffff;
}
.lpart3-img{
    width: 300px;
    height: 300px;
    border-radius: 5%;
}
.intro-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ff8400;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}
.intro-button:hover {
    background-color: #ffffff;
    color: #ff8400;
}

@media (max-width: 720px) {
    .lpart3-container0 {
        flex-direction: column;
        align-items: center;
    }
    .lpart3-img {
        width: 70%;
        height: auto;
    }
}

@media (max-width: 580px) {
    .lpart3-container0 {
        flex-direction: column;
        align-items: center;
    }
    .lpart3-img {
        width: 80%;
        height: auto;
    }
}

@media (max-width: 320px) {
    .lpart3-container0 {
        flex-direction: column;
        align-items: center;
    }
    .lpart3-img {
        width: 100%;
        height: auto;
    }
}