.team1-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.team1-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  text-align-last: center;
  flex-direction: column;
}
.team1-content01 {
  gap: var(--dl-space-space-sixunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-content02 {
  gap: 64px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.team1-row {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
.team1-container {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.team1-card {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image {
  width: 80px;
  height: 80px;
  padding-top: 4px;
}
.team1-content03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text03 {
  font-style: normal;
  font-weight: 600;
}
.team1-text04 {
  text-align: center;
}
.team1-social-icons {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.team1-card1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image1 {
  width: 80px;
  height: 80px;
}
.team1-content04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title1 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text05 {
  font-style: normal;
  font-weight: 600;
}
.team1-text06 {
  text-align: center;
}
.team1-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.team1-card2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image2 {
  width: 80px;
  height: 80px;
}
.team1-content05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title2 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text07 {
  font-style: normal;
  font-weight: 600;
}
.team1-text08 {
  text-align: center;
}
.team1-card3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image3 {
  width: 80px;
  height: 80px;
}
.team1-content06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title3 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text09 {
  font-style: normal;
  font-weight: 600;
}
.team1-text10 {
  text-align: center;
}
.team1-row1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
.team1-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.team1-card4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image4 {
  width: 80px;
  height: 80px;
}
.team1-content07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title4 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text11 {
  font-style: normal;
  font-weight: 600;
}
.team1-text12 {
  text-align: center;
}
.team1-social-icons1 {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.team1-card5 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image5 {
  width: 80px;
  height: 80px;
}
.team1-content08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title5 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text13 {
  font-style: normal;
  font-weight: 600;
}
.team1-text14 {
  text-align: center;
}
.team1-container3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.team1-card6 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image6 {
  width: 80px;
  height: 80px;
}
.team1-content09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title6 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text15 {
  font-style: normal;
  font-weight: 600;
}
.team1-text16 {
  text-align: center;
}
.team1-card7 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image7 {
  width: 80px;
  height: 80px;
}
.team1-content10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title7 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text17 {
  font-style: normal;
  font-weight: 600;
}
.team1-text18 {
  text-align: center;
}
.team1-row2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
.team1-container4 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.team1-card8 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image8 {
  width: 80px;
  height: 80px;
}
.team1-content11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title8 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text19 {
  font-style: normal;
  font-weight: 600;
}
.team1-text20 {
  text-align: center;
}
.team1-card9 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image9 {
  width: 80px;
  height: 80px;
}
.team1-content12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title9 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text21 {
  font-style: normal;
  font-weight: 600;
}
.team1-text22 {
  text-align: center;
}
.team1-container5 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.team1-card10 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image10 {
  width: 80px;
  height: 80px;
}
.team1-content13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title10 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text23 {
  font-style: normal;
  font-weight: 600;
}
.team1-text24 {
  text-align: center;
}
.team1-card11 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image11 {
  width: 80px;
  height: 80px;
}
.team1-content14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title11 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text25 {
  font-style: normal;
  font-weight: 600;
}
.team1-text26 {
  text-align: center;
}

@media(max-width: 991px) {
  .team1-content01 {
    gap: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 767px) {
  .team1-row {
    align-items: center;
    flex-direction: column;
  }
  .team1-row1 {
    align-items: center;
    flex-direction: column;
  }
  .team1-row2{
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 500px) {
  .team1-text {
    text-align: center;
  }
  .team1-text01 {
    text-align: center;
  }
  .team1-text02 {
    text-align: center;
  }
  .team1-container {
    flex-direction: column;
  }
  .team1-container1 {
    flex-direction: column;
  }
  .team1-container2 {
    flex-direction: column;
  }
  .team1-container3 {
    flex-direction: column;
  }
  .team1-container4 {
    flex-direction: column;
  }
  .team1-container5 {
    flex-direction: column;
  }
}
