.nlpfeature-layout349 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.nlpfeature-max-width {
  display: flex;
  align-items: center;
  gap: var(--dl-space-space-twounits);
}
.nlpfeature-container {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: var(--dl-color-theme-primary1);
}
.nlpfeature-container:nth-child(2),
.nlpfeature-container:nth-child(4) {
  background-color: var(--dl-color-theme-primary2);
}
.nlpfeature-image {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.nlpfeature-button {
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  border-color: azure;
  background-color: #134b42;
}
.nlpfeature-text {
  text-align: center;
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
}
.nlp-heading {
  font-family: Arial, Helvetica, sans-serif;
}

/* Responsive Styles */
@media (max-width: 991px) {
  .nlpfeature-max-width {
    flex-direction: column-reverse;
  }
}
@media (max-width: 767px) {
  .nlpfeature-container {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .nlpfeature-button {
    padding-left: var(--dl-space-space-unit);
  }
}
