.hero17-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.hero17-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.hero17-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.hero17-text {
  text-align: center;
}
.hero17-text1 {
  text-align: center;
}
.hero17-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
}
.hero17-button {
  text-decoration: none;
}
.hero17-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.hero17-row-container {
  width: 100%;
}
.hero17-placeholder-image {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image01 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image02 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image03 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image04 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image05 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image06 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image07 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image08 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image09 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image10 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image11 {
  width: 400px;
  height: 400px;
}
.hero17-row-container1 {
  width: 100%;
}
.hero17-placeholder-image12 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image13 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image14 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image15 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image16 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image17 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image18 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image19 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image20 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image21 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image22 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image23 {
  width: 400px;
  height: 400px;
}
.hero17-container1 {
  display: contents;
}
@media(max-width: 767px) {
  .hero17-content1 {
    width: 100%;
  }

  .hero17-text{
    font-size: xx-large;
  }

  .hero17-text1{
    font-size:medium;
  }

}
@media(max-width: 380px) {
  .hero17-actions {
    width: 100%;
    flex-direction: column;
  }

  .hero17-text{
    font-size: xx-large;
  }

  .hero17-text1{
    font-size: medium;
  }

  .hero17-button {
    width: 100%;
  }
}
