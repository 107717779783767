.contact10-container {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.contact10-max-width {
  align-self: center;
}
.contact10-content {
  width: 100%;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.contact10-content1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact10-content2 {
  width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.contact10-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact10-image {
  object-fit: cover;
}
.contact10-text1 {
  text-align: center;
}
.contact10-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact10-link {
  border-color: var(--dl-color-theme-neutral-dark);
  border-radius: var(--dl-radius-radius-buttonradius);
  text-decoration: none;
  background-color: var(--dl-color-theme-primary2);
}
.contact10-root-class-name {
  display: none;
}
@media(max-width: 991px) {
  .contact10-content {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .contact10-content2 {
    position: relative;
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .contact10-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .contact10-image {
    width: 100%;
  }
}
