.navbar81-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: sticky;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar81-navbar-interactive {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.navbar81-navlink {
  display: contents;
}
.navbar81-image1 {
  height: 3rem;
  text-decoration: none;
}
.navbar81-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar81-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.navbar81-link2 {
  text-decoration: none;
}
.navbar81-link3 {
  text-decoration: none;
}
.navbar81-link4 {
  text-decoration: none;
}
.navbar81-link5-dropdown-trigger {
  gap: 4px;
  cursor: pointer;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar81-link5 {
  text-decoration: none;
}
.navbar81-icon-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar81-container1 {
  display: flex;
  align-items: center;
  animation-name: rotateInDownLeft;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navbar81-icon {
  width: 24px;
  height: 24px;
}
.navbar81-container2 {
  display: flex;
  align-items: center;
  animation-name: rotateInDownRight;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navbar81-icon02 {
  width: 24px;
  height: 24px;
}
.navbar81-buttons {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar81-action1 {
  display: flex;
  flex-direction: row;
  text-decoration: none;
}
.navbar81-burger-menu {
  display: none;
}
.navbar81-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar81-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar81-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar81-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar81-navlink1 {
  display: contents;
}
.navbar81-logo {
  height: 3rem;
  text-decoration: none;
}
.navbar81-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar81-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar81-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}
.navbar81-link21 {
  text-decoration: none;
}
.navbar81-link31 {
  text-decoration: none;
}
.navbar81-link41 {
  text-decoration: none;
}
.navbar81-link5-accordion {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar81-trigger {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.navbar81-link51 {
  text-decoration: none;
}
.navbar81-icon-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar81-container3 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar81-icon08 {
  width: 24px;
  height: 24px;
}
.navbar81-container4 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar81-icon10 {
  width: 24px;
  height: 24px;
}
.navbar81-container5 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-halfunit);
  grid-template-columns: 1fr;
}
.navbar81-menu-item {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar81-page1-image {
  width: 50px;
  height: 50px;
}
.navbar81-content {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar81-page1 {
  font-style: normal;
  font-weight: 600;
}
.navbar81-menu-item1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar81-page2-image {
  width: 50px;
  height: 50px;
}
.navbar81-content1 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar81-page2 {
  font-style: normal;
  font-weight: 600;
}
.navbar81-menu-item2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar81-page3-image {
  width: 50px;
  height: 50px;
}
.navbar81-content2 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar81-page3 {
  font-style: normal;
  font-weight: 600;
}
.navbar81-menu-item3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar81-page4-image {
  width: 50px;
  height: 50px;
}
.navbar81-content3 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar81-page4 {
  font-style: normal;
  font-weight: 600;
}
.navbar81-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
}
.navbar81-action11 {
  text-decoration: none;
}
.navbar81-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navbar81-container6 {
  top: 100%;
  left: 0px;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  animation-name: fadeInDownBig;
  animation-delay: 0s;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navbar81-link5-menu-list {
  width: 100%;
  display: grid;
  padding: var(--dl-space-space-oneandhalfunits);
  grid-gap: var(--dl-space-space-halfunit);
  max-width: var(--dl-size-size-maxwidth);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.navbar81-menu-item4 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
}
.navbar81-page1-image1 {
  width: 30px;
  height: 30px;
}
.navbar81-content4 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar81-page11 {
  font-style: normal;
  font-weight: 600;
}
.navbar81-menu-item5 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
}
.navbar81-page2-image1 {
  width: 30px;
  height: 30px;
}
.navbar81-content5 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar81-page21 {
  font-style: normal;
  font-weight: 600;
}
.navbar81-menu-item6 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
}
.navbar81-page3-image1 {
  width: 30px;
  height: 30px;
}
.navbar81-content6 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar81-page31 {
  font-style: normal;
  font-weight: 600;
}
.navbar81-menu-item7 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
}
.navbar81-page4-image1 {
  width: 30px;
  height: 30px;
}
.navbar81-content7 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar81-page41 {
  font-style: normal;
  font-weight: 600;
}
.navbar81-container7 {
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: flex-start;
  flex-direction: column;
}





@media(max-width: 767px) {
  .navbar81-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar81-desktop-menu {
    display: none;
  }
  .navbar81-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar81-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .navbar81-container5 {
    grid-gap: 0;
  }
  .navbar81-link5-menu-list {
    display: none;
  }
}
@media(max-width: 479px) {
  .navbar81-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar81-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
