.features22-layout349 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.features22-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.features22-container {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  max-width: 600px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.features22-button {
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  border-color: azure;
  background-color: bisque;
}
.features22-buttom-ready {
  background-color: aqua; 
}
.features22-container1 {
  width: 100%;
}
.features22-card {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features22-image {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text02 {
  text-align: center;
}
.features22-card1 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.features22-image1 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text04 {
  text-align: center;
}
.features22-card2 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features22-image2 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text06 {
  text-align: center;
}
.features22-card3 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.features22-image3 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text08 {
  text-align: center;
}
.features22-card4 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.features22-image4 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text10 {
  text-align: center;
}
.features22-card5 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features22-image5 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text12 {
  text-align: center;
}
.features22-card6 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.features22-image6 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text14 {
  text-align: center;
}
.features22-card7 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features22-image7 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text16 {
  text-align: center;
}
.features22-card8 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.features22-image8 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text17 {
  text-align: center;
}
.features22-card9 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features22-image9 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text18 {
  text-align: center;
}
.features22-card10 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.features22-image10 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text19 {
  text-align: center;
}
.features22-card11 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features22-image11 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text20 {
  text-align: center;
}

@media(max-width: 991px) {
  .features22-max-width {
    flex-direction: column;
  }
  .features22-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .features22-container {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .features22-card {
    width: 100%;
  }
  .features22-card1 {
    width: 100%;
  }
  .features22-card2 {
    width: 100%;
  }
  .features22-card3 {
    width: 100%;
  }
  .features22-card4 {
    width: 100%;
  }
  .features22-card5 {
    width: 100%;
  }
  .features22-card6 {
    width: 100%;
  }
  .features22-card7 {
    width: 100%;
  }
  .features22-card8 {
    width: 100%;
  }
  .features22-card9 {
    width: 100%;
  }
  .features22-card10 {
    width: 100%;
  }
  .features22-card11 {
    width: 100%;
  }
}
