.faq11-faq8 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  color: #ffffff;
}
.faq11-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq11-section-title {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.faq11-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.faq11-text {
  text-align: center;
}
.faq11-text0{
  font-family: Arial, Helvetica, sans-serif;
  color: #eda733;
}
.faq11-text1 {
  text-align: center;
}
.faq11-list {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.faq11-faq1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.faq11-trigger {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq11-question {
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
}
.faq-text {
  font-family: Arial, Helvetica, sans-serif;
  color: #eda733;
}
.faq11-icons-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq11-icon {
  width: 24px;
  height: 24px;
}
.faq11-icon02 {
  width: 24px;
  height: 24px;
}
.faq11-container03 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq11-faq2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.faq11-trigger1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq11-faq2-question {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq11-icons-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq11-icon04 {
  width: 24px;
  height: 24px;
}
.faq11-icon06 {
  width: 24px;
  height: 24px;
}
.faq11-container06 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq11-faq3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.faq11-trigger2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq11-faq2-question1 {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq11-icons-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq11-icon08 {
  width: 24px;
  height: 24px;
}
.faq11-icon10 {
  width: 24px;
  height: 24px;
}
.faq11-container09 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq11-faq4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.faq11-trigger3 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq11-faq2-question2 {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq11-icons-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq11-icon12 {
  width: 24px;
  height: 24px;
}
.faq11-icon14 {
  width: 24px;
  height: 24px;
}
.faq11-container12 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq11-faq5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.faq11-trigger4 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq11-faq1-question1 {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq11-icons-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq11-icon16 {
  width: 24px;
  height: 24px;
}
.faq11-icon18 {
  width: 24px;
  height: 24px;
}
.faq11-container15 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq11-content1 {
  align-self: center;
}
.faq11-content2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.faq11-text7 {
  text-align: center;
}
.faq11-text8 {
  text-align: center;
}
.faq11-button {
  text-decoration: none;
}
@media(max-width: 991px) {
  .faq11-container {
    flex-direction: column;
  }
}
@media(max-width: 500px) {
  .faq11-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
