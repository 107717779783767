/*
.hero2-header5 {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
}
.hero2-image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.hero2-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.hero2-max-width {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.hero2-column {
  gap: 24px;
  width: auto;
  display: flex;
  z-index: 1;
  position: relative;
  max-width: 560px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.hero2-text {
  text-align: center;
  font-family: "STIX Two Text";
  color: #f380ff;
}
.hero2-text1 {
  text-align: center;
  color: #f380ff;
}
.hero2-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.hero2-container1 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
}
.hero2-container2 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
}
@media(max-width: 767px) {
  .hero2-header5 {
    justify-content: center;
  }
  .hero2-max-width {
    justify-content: center;
  }
  .hero2-column {
    width: 100%;
  }
  .hero2-text1 {
    text-align: center;
  }
  .hero2-actions {
    width: 100%;
    align-self: flex-start;
    justify-content: center;
  }
}
@media(max-width: 380px) {
  .hero2-actions {
    flex-direction: column;
  }
  .hero2-container1 {
    width: 100%;
  }
  .hero2-container2 {
    width: 100%;
  }
}
*/

.hero2-header5 {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
}

.hero2-swiper {
  width: 100%;
  height: auto;
}

.hero2-slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.hero2-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero2-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero2-text-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
  color: #ffffff;  
}

.hero2-text {
  text-align: left;
  font-family: "STIX Two Text";
  font-size: 36px;
}

.hero2-text1 {
  text-align: left;
  font-size: 18px;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px; 
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-bullet-active {
  background: #ffffff;
}

@media(max-width: 767px) {
  .hero2-header5 {
    height: auto;
    justify-content: center;
  }

  .hero2-swiper{
    height: auto;
  }

  .hero2-video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero2-text-container {
    bottom: 10px;
    left: 10px;
  }
}

@media(max-width: 380px) {
  .hero2-header5{
    height: auto;
    justify-content: center;
  }

  .hero2-text {
    font-size: 28px;
  }

  .hero2-swiper{
    height: auto;
  }

  .hero2-video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero2-text1 {
    font-size: 14px;
  }
}
